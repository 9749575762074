<template>
  <div>
    <v-row no-gutters v-if="fieldsLoading">
      <v-col v-for="n in 9" :key="n" cols="12" sm="6" lg="3">
       <v-sheet class="mt-2">
        <v-skeleton-loader class="mx-auto" type="list-item"></v-skeleton-loader>
      </v-sheet>
      </v-col>
    </v-row>
    <template v-else>
      <v-row class="my-2 mx-0">
        <v-col cols="12">
          <form-data :references.sync="formReferences" :model="modelObj" ref="chemicallistFormRef">
            <template v-if="modelObj.id && listOfDocuments.length > 0" slot="chemicallist_documents">
              <documents :documents="listOfDocuments" :preventFileDelete="preventFileDelete"></documents>
            </template>
          </form-data>
        </v-col>
      </v-row>
      <v-row class="mb-2 mx-0" v-if="modelObj.is_checklist_motor === 1">
        <v-col cols="12">
          <v-speed-dial v-model="showItems" bottom fixed class="custom-speed-dial">
            <template v-slot:activator>
              <v-btn v-model="showItems" color="primary" dark fab>
                <v-icon v-if="showItems">mdi-close</v-icon>
                <v-icon v-else>mdi-menu</v-icon>
              </v-btn>
            </template>
            <v-btn class="text-capitalize" min-width="80px" color="primary" v-for="item in speedDialItems" :key="`${item.name}_menuitem`" :to="item.to">{{ item.name }}</v-btn>
          </v-speed-dial>
        </v-col>
      </v-row>
    </template>
  </div>
</template>

<script>
import FormTemplate from '@/components/FormTemplate'
import { mapGetters } from 'vuex'
import { hostAppApi } from '../../plugins/axios_settings'
export default {
  data () {
    return {
      modelObj: {
        id: parseInt(this.$route.params.id)
      },
      listOfDocuments: [],
      showItems: false,
      hostRef: this.$cookie.get('WelditPwa_host-ref'),
      hostUserId: this.$cookie.get(process.env.VUE_APP_HOST_USER) ? this.$cookie.get(process.env.VUE_APP_HOST_USER) : 0,
      fieldsLoading: false,
      loading: false,
      isReadonly: true,
      backUrl: '',
      MODULE: '',
      hideDetails: true,
      showConfirm: false,
      preventFileDelete: true
    }
  },
  computed: {
    ...mapGetters(['formType']),
    formReferences () {
      return {
        title: this.$t('message.chemicallist.viewChemical'),
        properties: [{
          model: 'name',
          type: this.formType.TEXT,
          label: this.$t('message.chemicallist.chemical'),
          class: 'xs12 sm6 md4 lg3 helptext-style',
          disabled: this.isReadonly
        },
        // {
        //   model: 'provider',
        //   type: this.formType.TEXT,
        //   label: this.$t('message.chemicallist.provider'),
        //   class: 'xs12 sm6 md4 lg3 helptext-style',
        //   disabled: this.isReadonly
        // },
        {
          model: 'repository',
          type: this.formType.TEXT,
          label: this.$t('message.chemicallist.repository'),
          class: 'xs12 sm6 md4 lg3 helptext-style',
          disabled: this.isReadonly
        },
        // {
        //   model: 'responsible',
        //   type: this.formType.TEXT,
        //   label: this.$t('message.deviation.responsible'),
        //   class: 'xs12 sm6 md4 lg3 helptext-style',
        //   disabled: this.isReadonly
        // },
        // {
        //   model: 'last_revised',
        //   type: this.formType.DATEPICKER,
        //   label: this.$t('message.chemicallist.last_revised'),
        //   class: 'xs12 sm6 md4 lg3 helptext-style',
        //   disabled: this.isReadonly
        // },
        {
          model: 'document',
          type: this.formType.FILES,
          label: this.$t('message.deviation.upload_files'),
          class: 'xs12 sm6 md4 lg3 helptext-style',
          disabled: this.isReadonly,
          slot: 'chemicallist_documents'
        }, {
          model: 'description',
          type: this.formType.TEXTAREA,
          label: this.$t('message.deviation.description'),
          class: 'xs12 sm6 md4 lg3 helptext-style',
          disabled: this.isReadonly
        }],
        buttons: [{
          name: 'back',
          color: 'info',
          click: () => this.$router.go(-1), // this.$router.push(this.backUrl),
          label: this.$t('message.login.back'),
          to: this.backUrl,
          is_show: true
        }]
      }
    },
    speedDialItems () {
      const speedDialItems = []
      const chemicallistCheckList = {
        name: this.$t('message.checklist.title'),
        icon: 'mdi-book-plus',
        to: `/chemicallist/${this.modelObj.id}/checklists`
      }
      speedDialItems.push(chemicallistCheckList) // if (this.$store.state.common.includeEquipmentChecklist) speedDialItems.push(equipmentCheckList)
      return speedDialItems
    }
  },
  created () {
    this.MODULE = 'chemicallist'
  },
  mounted () {
    if (this.$route.params.id) {
      this.getSingleRecord(this.$route.params.id)
    }
    // set back url
    this.backUrl = '/chemicallist'
  },
  methods: {
    getSingleRecord (id) {
      this.fieldsLoading = true
      hostAppApi.get(`https://${this.hostRef}/api/chemicallist/${id}/edit?user_id=${this.hostUserId}`)
        .then((response) => {
          const model = response.data
          this.listOfDocuments = this.$formatter.removeInvalidFromArrayObject(model.documents)
          if (response.data) this.modelObj = this.$formatter.cloneVariable(model)
        })
        .finally(() => {
          this.fieldsLoading = false
        })
    }
  },
  components: {
    'form-data': FormTemplate,
    documents: () => import('@/components/Documents/Documents')
  }
}
</script>

<style>
.custom-speed-dial div.v-speed-dial__list {
  align-items: baseline !important;
}
@media only screen and (min-width: 768px) {
  .v-speed-dial--bottom {
    bottom: 14vh !important;
  }
}
@media only screen and (max-width: 767px) {
  .v-speed-dial--bottom {
    bottom: 10vh !important;
  }
}
.helptext-style > span {
  font-size: 80%;
  padding-left: 2%;
  color: red;
}
</style>
